<template>
    <div class="box page">
        <detail :orderId="orderId"></detail>
    </div>
    
</template>

<script>
import detail from '../../scrm_pc/order/orderDetail'  
export default {
    components: {
        'detail': detail  //将别名demo 变成 组件 Demo
    },
    data() {
        return {
            orderId: ""
        }
    },
    mounted: function(){
        console.log(this.$route.query.id);
        this.orderId = this.$route.query.id;
    },
}

</script>
<style>
    
</style>
